@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .osano-cm-window__widget > svg > path:nth-of-type(1) {
    stroke: rgba(255, 255, 255, 1) !important;
    fill-opacity: 0;
  }
  .osano-cm-window__widget > svg > path:nth-of-type(2) {
    stroke: rgba(255, 255, 255, 0) !important;
    fill-opacity: 0;
  }
  .osano-cm-window__widget > svg > g {
    fill: rgba(255, 255, 255, 1) !important;
  }
  .osano-cm-info {
    text-transform: none !important;
    box-shadow: 0 0 0 2px #333 !important;
  }
  .osano-cm-description p {
    font-size: 12px !important;
  }
  .osano-cm-drawer-toggle .osano-cm-label {
    font-size: 14px !important;
  }
  .osano-cm-header {
    font-size: 16px !important;
  }
  .osano-cm-message {
    text-transform: none !important;
    font-size: 16px !important;
  }
}
